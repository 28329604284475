import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Company = () => {
    return (
        <div className="container py-3 py-lg-4">
            <div className="row g-0 mb-3 " >
                <div className="col border-bottom border-1 border-dark">
                    <h1>Asset Management</h1>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12 col-md-6 mb-3 mb-md-0 ">
                    <div className="card-body h-100 gray-bg d-flex flex-column"  style={{ padding: '16px' }}>
                        <h2 className="card-title" style={{ marginBottom: '8px' }}>We manage ships. We manage your assets.</h2>
                        <p className="card-text">
                            Its all about the people onboard and ashore working towards a single goal – operational excellence through
                            attention to detail.
                        </p>
                        <p>
                            Delta Corp Asset Management is a ship manager strategically located across Dubai, Copenhagen, Singapore &
                            India.
                        </p>

                        <div className="text-end border-top border-2 border-dark pt-3 mt-auto">
                            <Link to="/asset-management/company/about-us" className="text-dark">
                                Learn more 
                                <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="card border-0 h-100 card-product ">
                        <div className="card-body d-flex flex-column">
                            <h2 className="text-light">Core values</h2>
                            <div className="mb-3">
                                <div className=" text-light fw-bold mb-1">
                                <svg width="1.15em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="anchor" class="svg-inline--fa fa-anchor fa-w-18 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M571.515 331.515l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h44.268C453.531 417.326 380.693 456.315 312 462.865V216h60c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-60v-11.668c32.456-10.195 56-40.512 56-76.332 0-44.183-35.817-80-80-80s-80 35.817-80 80c0 35.82 23.544 66.138 56 76.332V168h-60c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h60v246.865C195.192 456.304 122.424 417.176 102.762 352h44.268c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.03 67.029C-3.074 339.074 2.28 352 12.971 352h40.284C73.657 451.556 181.238 512 288 512c113.135 0 215.338-65.3 234.745-160h40.284c10.691 0 16.045-12.926 8.486-20.485zM288 48c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"></path></svg>
                                Innovation
                                </div>
                                <div className=" text-light">Open and creative to customer and employee solutions.</div>
                            </div>
                            <div className="mb-3">
                                <div className=" text-light fw-bold mb-1">
                                <svg width="1.15em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="anchor" class="svg-inline--fa fa-anchor fa-w-18 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M571.515 331.515l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h44.268C453.531 417.326 380.693 456.315 312 462.865V216h60c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-60v-11.668c32.456-10.195 56-40.512 56-76.332 0-44.183-35.817-80-80-80s-80 35.817-80 80c0 35.82 23.544 66.138 56 76.332V168h-60c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h60v246.865C195.192 456.304 122.424 417.176 102.762 352h44.268c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.03 67.029C-3.074 339.074 2.28 352 12.971 352h40.284C73.657 451.556 181.238 512 288 512c113.135 0 215.338-65.3 234.745-160h40.284c10.691 0 16.045-12.926 8.486-20.485zM288 48c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"></path></svg>  
                                    Transparancy
                                </div>
                                <div className=" text-light">We communicate openly.</div>
                            </div>
                            <div className="mb-3">
                                <div className=" text-light fw-bold mb-1">
                                <svg width="1.15em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="anchor" class="svg-inline--fa fa-anchor fa-w-18 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M571.515 331.515l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h44.268C453.531 417.326 380.693 456.315 312 462.865V216h60c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-60v-11.668c32.456-10.195 56-40.512 56-76.332 0-44.183-35.817-80-80-80s-80 35.817-80 80c0 35.82 23.544 66.138 56 76.332V168h-60c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h60v246.865C195.192 456.304 122.424 417.176 102.762 352h44.268c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.03 67.029C-3.074 339.074 2.28 352 12.971 352h40.284C73.657 451.556 181.238 512 288 512c113.135 0 215.338-65.3 234.745-160h40.284c10.691 0 16.045-12.926 8.486-20.485zM288 48c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"></path></svg>  
                                    Compliance
                                </div>
                                <div className=" text-light">
                                    Our strict compliance regime creates reliable business ecosystem for our clients, employees, and
                                    industry partners.
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className=" text-light fw-bold mb-1">
                                <svg width="1.15em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="anchor" class="svg-inline--fa fa-anchor fa-w-18 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M571.515 331.515l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h44.268C453.531 417.326 380.693 456.315 312 462.865V216h60c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-60v-11.668c32.456-10.195 56-40.512 56-76.332 0-44.183-35.817-80-80-80s-80 35.817-80 80c0 35.82 23.544 66.138 56 76.332V168h-60c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h60v246.865C195.192 456.304 122.424 417.176 102.762 352h44.268c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.03 67.029C-3.074 339.074 2.28 352 12.971 352h40.284C73.657 451.556 181.238 512 288 512c113.135 0 215.338-65.3 234.745-160h40.284c10.691 0 16.045-12.926 8.486-20.485zM288 48c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"></path></svg>   
                                    Goal orientated
                                </div>
                                <div className=" text-light">
                                    Shared Goal & Initiative Alignment.We accomplish our goals more efficiently.
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className=" text-light fw-bold mb-1">
                                <svg width="1.15em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="anchor" class="svg-inline--fa fa-anchor fa-w-18 fa-fw me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M571.515 331.515l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.029 67.029c-7.56 7.56-2.206 20.485 8.485 20.485h44.268C453.531 417.326 380.693 456.315 312 462.865V216h60c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12h-60v-11.668c32.456-10.195 56-40.512 56-76.332 0-44.183-35.817-80-80-80s-80 35.817-80 80c0 35.82 23.544 66.138 56 76.332V168h-60c-6.627 0-12 5.373-12 12v24c0 6.627 5.373 12 12 12h60v246.865C195.192 456.304 122.424 417.176 102.762 352h44.268c10.691 0 16.045-12.926 8.485-20.485l-67.029-67.029c-4.686-4.686-12.284-4.686-16.971 0l-67.03 67.029C-3.074 339.074 2.28 352 12.971 352h40.284C73.657 451.556 181.238 512 288 512c113.135 0 215.338-65.3 234.745-160h40.284c10.691 0 16.045-12.926 8.486-20.485zM288 48c17.645 0 32 14.355 32 32s-14.355 32-32 32-32-14.355-32-32 14.355-32 32-32z"></path></svg>    
                                    Teamwork
                                </div>
                                <div className=" text-light">At Delta, we support each other and our clients as one team.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12">
                    <div className="card-body" style={{ padding: '16px' }}>
                        <h2 className="card-title" style={{ marginBottom: '8px' }}>Our expertise</h2>
                    </div>
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to="0"
                                class="active"
                                aria-current="true"
                                aria-label="Slide 1"
                            ></button>
                            <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to="1"
                                aria-label="Slide 2"
                            ></button>
                            <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to="2"
                                aria-label="Slide 3"
                            ></button>
                            <button
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to="3"
                                aria-label="Slide 4"
                            ></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <StaticImage
                                    transformOptions={{ cropFocus: "entropy" }}
                                    alt="Sales team"
                                    src="../../../images/other/service8.jpg"
                                    aspectRatio={16 / 6}
                                    width={1296}
                                />
                                <div class="carousel-caption ">
                                    <h2>Ocean Going Vessels</h2>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/service3.jpg"
                                    aspectRatio={16 / 6}
                                    width={1296}
                                />
                                <div class="carousel-caption">
                                    <h2>Drill Ships and Semi Submersibles</h2>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/service4.jpg"
                                    aspectRatio={16 / 6}
                                    width={1296}
                                />
                                <div class="carousel-caption ">
                                    <h2>Jack-Ups</h2>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/service6.jpg"
                                    aspectRatio={16 / 6}
                                    width={1296}
                                />
                                <div class="carousel-caption ">
                                    <h2>Power Generation Plants and Mining Gensets</h2>
                                </div>
                            </div>
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExampleIndicators"
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12">
                    <div className="card-body h-100 gray-bg d-flex flex-column" style={{ padding: '16px' }}>
                        <h2 className="card-title" style={{ marginBottom: '8px' }}>Our Mantra is “Execution, execution, execution”.</h2>
                        <p className="card-text" style={{ marginBottom: '8px' }}>
                            We believe in Safety of people and ships, Transparency towards our customers on financial and operational
                            matters, Asset Protection so our customers can take advantage of enhanced market valuation, and Reliability
                            in performance.
                        </p>
                        <p> We believe in:</p>
                        <p>
                            <ul className="mb-0">
                                <li>Safety of people and ships,</li>
                                <li>Transparency towards our customers on financial and operational matters,</li>
                                <li>Asset Protection so our customers can take advantage of enhanced market valuation,</li>
                                <li>Reliability and Consistency in performance.</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-12">
                    <StaticImage
                        transformOptions={{ cropFocus: "attention" }}
                        alt="Sales team"
                        src="../../../images/other/bulker6.jpg"
                        aspectRatio={16 / 8}
                        width={1296}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card-body h-100 gray-bg d-flex flex-column" style={{ padding: '16px' }}>
                        <div className="row mb-5">
                            <div className="col">
                                <h2 className="card-title">What makes us different?</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-4 text-center">
                            <svg width="4em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="robot" class="svg-inline--fa fa-robot fa-w-20 fa-fw fa-4x me-2 mb-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M192,408h64V360H192ZM576,192H544a95.99975,95.99975,0,0,0-96-96H344V24a24,24,0,0,0-48,0V96H192a95.99975,95.99975,0,0,0-96,96H64a47.99987,47.99987,0,0,0-48,48V368a47.99987,47.99987,0,0,0,48,48H96a95.99975,95.99975,0,0,0,96,96H448a95.99975,95.99975,0,0,0,96-96h32a47.99987,47.99987,0,0,0,48-48V240A47.99987,47.99987,0,0,0,576,192ZM96,368H64V240H96Zm400,48a48.14061,48.14061,0,0,1-48,48H192a48.14061,48.14061,0,0,1-48-48V192a47.99987,47.99987,0,0,1,48-48H448a47.99987,47.99987,0,0,1,48,48Zm80-48H544V240h32ZM240,208a48,48,0,1,0,48,48A47.99612,47.99612,0,0,0,240,208Zm160,0a48,48,0,1,0,48,48A47.99612,47.99612,0,0,0,400,208ZM384,408h64V360H384Zm-96,0h64V360H288Z"></path></svg>                                
                            <div className="fw-bold mb-3">Technology Driven</div>
                                <p className="text-start">
                                    Delta leads innovation by using adaptive AI and ML in all its business activities.
                                </p>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                            <svg width="4em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="search" class="svg-inline--fa fa-search fa-w-16 fa-fw fa-4x me-2 mb-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"></path></svg>                                
                            <div className="fw-bold mb-3">Enhanced Transparency</div>
                                <p className="text-start">
                                    Virtual Vessel at a click of a button to enable you to see your vessels performance, operation and
                                    financial results.
                                </p>
                            </div>
                            <div className="col-12 col-md-4 text-center">
                            <svg width="4em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="users" class="svg-inline--fa fa-users fa-w-20 fa-fw fa-4x me-2 mb-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-112c17.6 0 32 14.4 32 32s-14.4 32-32 32-32-14.4-32-32 14.4-32 32-32zm396.4 210.9c-27.5-40.8-80.7-56-127.8-41.7-14.2 4.3-29.1 6.7-44.7 6.7s-30.5-2.4-44.7-6.7c-47.1-14.3-100.3.8-127.8 41.7-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c.2-23.8-7-45.9-19.4-64.3zM464 432H176v-44.8c0-36.4 29.2-66.2 65.4-67.2 25.5 10.6 51.9 16 78.6 16 26.7 0 53.1-5.4 78.6-16 36.2 1 65.4 30.7 65.4 67.2V432zm92-176h-24c-17.3 0-33.4 5.3-46.8 14.3 13.4 10.1 25.2 22.2 34.4 36.2 3.9-1.4 8-2.5 12.3-2.5h24c19.8 0 36 16.2 36 36 0 13.2 10.8 24 24 24s24-10.8 24-24c.1-46.3-37.6-84-83.9-84zm-236 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-176c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zM154.8 270.3c-13.4-9-29.5-14.3-46.8-14.3H84c-46.3 0-84 37.7-84 84 0 13.2 10.8 24 24 24s24-10.8 24-24c0-19.8 16.2-36 36-36h24c4.4 0 8.5 1.1 12.3 2.5 9.3-14 21.1-26.1 34.5-36.2z"></path></svg>                                
                            <div className="fw-bold mb-3">Teamwork</div>
                                <p className="text-start">
                                    Single cohesive unit across all the facets of ship management as a leading Asset Manager.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company
